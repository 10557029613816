import React, { useEffect, useState } from 'react';
import BtnCommentaire from '../Commentaire/BtnCommentaire/BtnCommentaire';
import CenteredPopin from '../Popin/CenteredPopin/CenteredPopin';
import styles from '../Commentaire/EditCommentaire/EditCommentaire.module.scss';
import { thinSpace } from '../../../utils/french';
import Input from '../Form/Input/Input';
import Button from '../Button/Button';

interface Props {
	close: any
	input: string,
	commanHandler: any
	handleVal: any
}

function EditGlobalCommentaire({ handleVal, close, input, commanHandler }: Props) {
	console.log(input);

	// const [value, setValue] = useState('');

	function trigger() {
		commanHandler();
		close();
	}

	return (
		<>
			<CenteredPopin isOpen={true} onCacheClick={() => close(false)}>
				<h1 className={styles.title}>Commentaire{thinSpace}:</h1>

				<Input
					label=""
					autoFocus
					type="textarea"
					value={input}
					className={styles.textarea}
					setValue={(val) => handleVal(val)}
				/>

				<div className={styles.validerWrapper}>
					<Button className={styles.valider} onClick={() => trigger()}>
						Valider
					</Button>
				</div>
			</CenteredPopin>
		</>
	);
}

export default EditGlobalCommentaire;
