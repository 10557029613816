import React from 'react';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { ProduitForPanier } from '../../../../fragments/produitForPanier/type';
import {
	removeProduitFromPanier,
	setGlobalCommentForFournisseur,
} from '../../../../store/panier/actions';
import { Panier } from '../../../../store/panier/types';
import { Fournisseur, Tarif } from '../../../../types';
import { photosActualitesSizes } from '../../../../utils/imgSizes';
import { loadStateFromLocalStorage } from '../../../../utils/localStorage';
import BtnPoubelle from '../../../Common/BtnPoubelle/BtnPoubelle';
import EditCommentaire from '../../../Common/Commentaire/EditCommentaire/EditCommentaire';
import Img from '../../../Common/Img/Img';
import Label from '../../../Common/Label/Label';
import Prix from '../../../Common/Prix/Prix';
import Table from '../../../Common/Table/Table';
import { RowProps } from '../../../Common/Table/types';
import HeaderLignesFournisseur from '../../HeaderLignesFournisseur/HeaderLignesFournisseur';
import PlusMoinsPanier from '../../PlusMoinsPanier/PlusMoinsPanier';
import styles from './TableProduits.module.scss';

export interface Props {
	error?: string;
	id: string;
	valid?: boolean;
	loading?: boolean;
	removeProduitFromPanier: typeof removeProduitFromPanier;
	setGlobalCommentForFournisseur: typeof setGlobalCommentForFournisseur;
	fournisseur?: Pick<
		Fournisseur,
		| 'enseigne'
		| 'dateLivraison'
		| 'franco'
		| 'optionLivraison'
		| 'timeLivraison'
		| 'dayDelivery'
		| 'datesFermetureExceptionnelle'
	>;
	lignes: (Pick<Panier['lignes'][0], 'quantite' | 'ancienPrix'> & {
		produit: Pick<
			ProduitForPanier,
			'id' | 'imageSrc' | 'labels' | 'nom' | 'unite'
		> & { tarif: Pick<Tarif, 'prix'> };
	})[];
}

export function _TableProduits({
	error,
	id,
	lignes,
	valid,
	loading,
	fournisseur,
	removeProduitFromPanier,
}: Props) {
	return (
		<>
			{fournisseur && (
				<HeaderLignesFournisseur
					id={id}
					panier={loadStateFromLocalStorage('panier')}
					enseigne={fournisseur.enseigne}
					closedDay={fournisseur.datesFermetureExceptionnelle}
					optionLivraison={fournisseur.optionLivraison}
					timeLivraison={fournisseur.timeLivraison}
					dayDelivery={fournisseur.dayDelivery}
					dateLivraison={fournisseur.dateLivraison}
				/>
			)}

			{valid ? (
				<></>
			) : (
				<div className={styles.wrapper}>
					<div className={styles.fournisseur}>
						Attention :{' '}
						<strong>
							Le montant de la commande n'atteint pas le montant minimum demandé
							par ce fournisseur ( {fournisseur?.franco}€ ){' '}
						</strong>
					</div>
				</div>
			)}
			<Table
				error={error}
				loading={loading}
				noResultMessage="Votre panier est vide"
				columns={[
					{ content: 'Photo', center: true, mobileHidden: true },
					{ content: 'Nom', mobileHidden: true },
					{ content: 'Commentaire', center: true },
					{ content: 'Labels' },
					{ content: 'Prix' },
					{ content: 'Quantité' },
					{ content: 'Total' },
					{ content: 'Enlever', center: true, isButton: true },
				]}
				rows={lignes
					.sort((a, b) => (a.produit.nom > b.produit.nom ? 1 : -1))
					.map(({ ancienPrix, produit, quantite }) => {
						const { id, imageSrc, labels, nom, unite } = produit;
						const { prix } = produit.tarif;

						const image = <ReactSVG src={imageSrc} className={styles.photo} />;

						const specialMessage = prix !== ancienPrix && (
							<p className={styles.warning}>
								Le prix de ce produit a changé depuis que vous l'avez ajouté à
								votre panier.
							</p>
						);

						return {
							id,
							mobileTitle: (
								<div className={styles.mobileTitle}>
									<Img
										src={imageSrc}
										alt={imageSrc}
										fallback="jpg"
										otherSizes={photosActualitesSizes}
										mediaQueries={[{ width: 100 }]}
									/>
									{nom}
								</div>
							),

							specialMessageOnMobile: specialMessage,

							cells: [
								// Photo produit
								// { content: image },
								{
									content: (
										<Img
											src={imageSrc}
											alt={imageSrc}
											fallback="jpg"
											otherSizes={photosActualitesSizes}
											mediaQueries={[
												{ breakpoint: '<=mobile', width: 'calc(100vw - 80px)' },
												{ breakpoint: '<laptop', width: 'calc(50vw - 140px)' },
												{ width: 315 },
											]}
										/>
									),
								},

								// Nom & warning / error
								{
									content: (
										<>
											<p className={styles.nom}>{nom}</p>
											{specialMessage}
										</>
									),
								},

								{ content: <EditCommentaire codeProduit={id} /> },

								// Labels
								{
									content: labels.length
										? labels.map((label, i) => (
												<Label key={i}>{label.abreviation}</Label>
										  ))
										: null,
								},

								// Prix unitaire
								{
									content: (
										<Prix
											afficherNA
											prix={prix}
											unite={unite}
											aucour={prix === -1}
										/>
									),
								},

								// Quantité
								{ content: <PlusMoinsPanier idProduit={id} /> },

								// Total
								{
									content: (
										<Prix
											afficherNA
											prix={(prix as any) * quantite}
											aucour={prix === -1}
										/>
									),
								},

								// Supprimer le produit
								{
									content: (
										<BtnPoubelle
											title="Enlever le produit du panier"
											onClick={() => removeProduitFromPanier(id)}
										/>
									),
								},
							],
						} as RowProps;
					})}
			/>
		</>
	);
}

export default connect(null, {
	removeProduitFromPanier,
	setGlobalCommentForFournisseur,
})(_TableProduits);
