import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { useTitle } from '../../../hooks/useTitle';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import FormulaireActualite from '../FormulaireActualite/FormulaireActualite';

const createMutation = loader('./createActualite.graphql');

function CreationActualite() {
	useTitle('Nouvelle actualité');

	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
const [embedVal, setEmbedVal] = useState('');
	const [titre, changeTitre] = useState('');
	const [contenu, changeContenu] = useState('');
	const [url, changeUrl] = useState('');
	const [imageSrc, changeImageSrc] = useState('');
	const [imageFile, setImageFile] = useState<File | undefined>(undefined);

	async function submit() {
		try {
			setError('');
			setLoading(true);
			await request(createMutation, { url, titre, contenu, image: embedVal != '' ? embedVal : imageFile});
			if (isMounted.current) setDone(true);
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	}

	return done ? (
		<Redirect to="/actualites" />
	) : (
		<FormulaireActualite
			type="Création"
			{...{
				error,
				loading,
				url,
				titre,
				contenu,
				imageSrc,
				changeUrl,
				changeTitre,
				setImageFile,
				changeContenu,
				changeImageSrc,
				embedVal,
				setEmbedVal,
				submit,
			}}
		/>
	);
}

export default CreationActualite;
