import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { addProduitToPanier } from '../../../../store/panier/actions';
import { PanierState } from '../../../../store/panier/types';
import { photosProduitsSizes } from '../../../../utils/imgSizes';
import request from '../../../../utils/request';
import BtnFavori from '../../../Common/BtnFavori/BtnFavori';
import EditCommentaire from '../../../Common/Commentaire/EditCommentaire/EditCommentaire';
import Img from '../../../Common/Img/Img';
import Label from '../../../Common/Label/Label';
import OrigineMark from '../../../Common/OrigineMark/OrigineMark';
import Prix from '../../../Common/Prix/Prix';
import BtnAjoutPanier from '../../BtnAjoutPanier/BtnAjoutPanier';
import PlusMoinsPanier from '../../PlusMoinsPanier/PlusMoinsPanier';
import { QueryResponse } from '../ResultatsRecherche';
import styles from './VignetteProduit.module.scss';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ReactComponent as OeilSVG } from '../VignetteFournisseur/oeil.svg';

const setProduitFavoriMutation = loader('./setProduitFavori.graphql');

export interface Props {
	produit: QueryResponse['produits'][0];
	lignesPanier: PanierState['lignes'];
	addProduitToPanier: typeof addProduitToPanier;

}


export function _VignetteProduit({
																	 produit,
																	 lignesPanier,
																	 addProduitToPanier,

																 }: Props) {
	const [isFavori, setIsFavori] = useState(produit.isFavoriteForMe);
	const isInPanier = !!lignesPanier.find((l) => l.produit.id === produit.id);
	const prixUnitaireFinal = produit.tarifs[produit.tarifs.length - 1] && produit.tarifs[produit.tarifs.length - 1].prix;



	const enseigne =
		produit.tarifs[produit.tarifs.length - 1] &&
		produit.tarifs[produit.tarifs.length - 1].fournisseur.enseigne;

	const idFournisseur =
		produit.tarifs[produit.tarifs.length - 1] &&
		produit.tarifs[produit.tarifs.length - 1].fournisseur.id;



	function ajouterAuxFavoris() {
		setIsFavori(!isFavori);
		request(setProduitFavoriMutation, {
			favori: !isFavori,
			idProduit: produit.id,
		});
	}

	function goToFournisseur() {

	}

	return (
		<div
			className={[
				styles.wrapper,
				styles.VignetteProduit,
				isInPanier && styles.inPanier,
			]
				.filter(Boolean)
				.join(' ')}
		>
			{/* Ajout / Suppression des favoris */}
			<BtnFavori
				title={
					isFavori
						? 'Enlever le produit de mes favoris'
						: 'Ajouter le produit à mes favoris'
				}
				className={styles.btnFavori}
				onClick={ajouterAuxFavoris}
				checked={isFavori}
			/>

			<div className={styles.left}>
				{/* Photo du produit */}
				<Img
					alt={produit.nom}
					fallback="jpg"
					src={produit.imageSrc}
					className={styles.photo}
					otherSizes={photosProduitsSizes}
					mediaQueries={[{ width: styles.photoWidth }]}
				/>

				{/* Labels */}
				<div className={styles.labels}>
					{produit.labels.map(({ abreviation }, i) => (
						<Label key={i}>{abreviation}</Label>
					))}
					&nbsp;
				</div>
			</div>

			<div className={styles.right}>
				{/* Nom */}
				<div className={styles.prix}>{produit.codeFournisseur.length > 0 ? produit.codeFournisseur[0].code : ''} </div>
				<h1 className={styles.nom}>{produit.nom}</h1>


				<Link
					className={styles.link}
					to={'/recherche' + '?' + 'fournisseur=' + idFournisseur}
					title={'Voir fournisseur'}
				>
					<h1 className={styles.subnom}>{enseigne}</h1>
				</Link>

				{/*<div*/}
				{/*	className={styles.link}*/}
				{/*>*/}
				{/*	<h1 className={styles.prix} >Code : </h1>*/}
				{/*</div>*/}
				{/* Origine & marques */}
				<OrigineMark origine={produit.origines} mark={produit.mark} />

				{/* Prix unitaire */}
				<Prix
					className={[styles.prix, isInPanier && styles.prixOpen]
						.filter(Boolean)
						.join(' ')}
					prix={prixUnitaireFinal}
					aucour={produit.aucour}
					unite={produit.unite}
				/>

				{/* Ajout au panier */}
				{isInPanier ? (
					<>
						<div className={styles.btnCommentaireVignette}>
							<EditCommentaire codeProduit={produit.id} />
						</div>
						<PlusMoinsPanier
							idProduit={produit.id}
							className={styles.btnPanier}
						/>
					</>
				) : (
					prixUnitaireFinal ? <BtnAjoutPanier
						className={styles.btnPanier}
						title="Ajouter le produit au panier"
						onClick={() => {
							const { tarifs, ...rest } = produit;
							const tar = produit.tarifs[produit.tarifs.length - 1];
							if (produit.aucour) {
								tar.prix = 'Au cours';
							}
							return addProduitToPanier({
								quantite: 1,
								commentaire: null,
								produit: { ...rest, tarif: tar },
							});
						}}
					/> : <BtnAjoutPanier
						className={styles.btnPanierDisabled}
						title="Ajouter le produit au panier"
						onClick={() => {

						}}
					/>
				)}
			</div>
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return { lignesPanier: state.panier.lignes };
}

export default connect(mapStateToProps, { addProduitToPanier })(
	_VignetteProduit,
);
