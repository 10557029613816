import formatDate from 'dateformat';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from '../../../../store';
import { resetPanier } from '../../../../store/panier/actions';
import { Panier } from '../../../../store/panier/types';
import { NewCommande } from '../../../../types';
import request from '../../../../utils/request';
import Button from '../../../Common/Button/Button';
import Loader from '../../../Common/Loader/Loader';
import CenteredPopin from '../../../Common/Popin/CenteredPopin/CenteredPopin';
import styles from './PopinCreationCommande.module.scss';
import { loadStateFromLocalStorage } from '../../../../utils/localStorage';
import { addDays } from 'date-fns';

const mutation = loader('./createCommande.graphql');

export interface Props extends Panier {
	isOpen: boolean;
	resetPanier: typeof resetPanier;
	close: () => void;
}

export function _PopinCreationCommande({
																				 isOpen,
																				 close,
																				 resetPanier,
																				 ...panier
																			 }: Props) {
	const [loading, setLoading] = useState(false);
	const [commandeCree, setCommandeCree] = useState(false);
	const [error, setError] = useState('');
	const [local, setLoacl] = useState({});
	const [idPanier, setIdPanier] = useState('');
	const [fournisseurArray, setFournisseurArray] = useState([]);
	const days = [
		'DIMANCHE',
		'LUNDI',
		'MARDI',
		'MERCREDI',
		'JEUDI',
		'VENDREDI',
		'SAMEDI',
	];
	const disabled = [
		'LUNDI',
		'MARDI',
		'MERCREDI',
		'JEUDI',
		'VENDREDI',
		'SAMEDI',
		'DIMANCHE',
	];
	// On reset la popin uniquement quand on ouvre la popin
	useEffect(() => {
		const date = panier.dateLivraison || new Date();
		let dateLivraisonVal = new Date();

		const fourni = (panier.lignes.map((e) => {

			const disblaedDate = e.produit.tarif?.fournisseur.datesFermetureExceptionnelle.map((e: string) => new Date(e).toDateString());
			const posible = e.produit.tarif?.fournisseur.dayDelivery
				.split(',')
				.map((e: string) => {
					if (disabled.includes(e)) {
						return e;
					}
				})
				.filter((e: any) => e != undefined);
			const now = new Date().getHours();
			if (now > e.produit.tarif?.fournisseur.timeLivraison && formatDate(date, "dd/mm/yyyy") === formatDate(dateLivraisonVal, "dd/mm/yyyy")) {
				dateLivraisonVal = e.produit.tarif?.fournisseur.optionLivraison === 0 ? addDays(
					new Date(date), 1,
				) : date
			} else {
				dateLivraisonVal = new Date(date);
			}
			let theDate = new Date(dateLivraisonVal);
			let newDate = new Date();
			let dayTrue = false;
			let closeTrue = false;
			while (!dayTrue && !closeTrue) {
				const day = days[theDate.getDay()];
				if (!posible.includes(day) || disblaedDate?.includes(theDate.toDateString())) {
					dayTrue = false;
					closeTrue = false;
					theDate = addDays(theDate, 1);
				} else {
					if (theDate.getDate() - newDate.getDate() >= e.produit.tarif?.fournisseur.optionLivraison){
						console.log('if')
						theDate = theDate
						dayTrue = true;
						closeTrue = true;
					}else {
						const daysToAdd = theDate.getDate() - newDate.getDate() ;
						theDate = daysToAdd >= 0 && theDate.getMonth() === newDate.getMonth() ?  addDays(theDate, e.produit.tarif?.fournisseur.optionLivraison - daysToAdd ) : theDate
						dayTrue = true;
						closeTrue = true;
					}
				}

			}


			return {
				id: e.produit.tarif?.fournisseur.id,
				enseigne: e.produit.tarif?.fournisseur.enseigne,
				estimated: formatDate(theDate.toDateString(), 'dd/mm/yyyy'),
			};
		}) as any);

		setFournisseurArray((Array.from(new Set(fourni.map((a: any) => a.id))).map(id => {
			return fourni.find((a: any) => a.id === id);
		}) as any));
		if (!isOpen) return;
		setLoading(false);
		setCommandeCree(false);
		let localPanier = loadStateFromLocalStorage('panier');
		let panierFav = loadStateFromLocalStorage('favCmd');
		setIdPanier(!!panierFav ? panierFav.toString() : '');


		setLoacl((localPanier as any));
		setError('');
	}, [isOpen]);

	// Fonction qui passe la commande
	async function placeOrder() {
		try {
			setError('');
			setLoading(true);

			const lignesFormat = Promise.all((local as any).lignes.map(async (l: any, index: number) => {
				const prod = await panier.lignes.find((e: any) => {
					return e.produit.id === l.idProduit;
				});

				setTimeout(() => {
				}, 500);
				if (prod) {
					// console.log(index, panier.lignes, (local as any).lignes);
					const tarif = (prod as any).produit.tarif!.fournisseur.id;
					const prix = (prod as any).produit.tarif!.prix;
					// console.log(l);
					return {
						quantite: l.quantite,
						idProduit: l.idProduit,
						prixUnitaireAConfirmer: prix,
						idFournisseur: tarif,
						uc: l.uc,
						commentaire: l.commentaire ? l.commentaire : '',
					};
				}
			})).then((e) => {
				const date = (fournisseurArray[0] as any).estimated;

				// console.log(, new Date((fournisseurArray[0] as any).estimated));
				const lignes = e.filter((e: any) => e !== null).filter((e) => e !== undefined);
				const newCommande: NewCommande = {
					...panier,
					id: idPanier,
					dateLivraison: formatDate(date.split('/').reverse().join('/').replace('/', '-').replace('/', '-'), 'yyyy-mm-dd'),
					comments: [...(local as any).comments],
					lignes: lignes as NewCommande['lignes'],
				};
				newCommande.favorite = false;

				request(mutation, newCommande).then((e) => {
					setCommandeCree(true);
					resetPanier();
				});
			});
		} catch (err) {
			console.log(err);
			setError(err.message);
		} finally {
			setLoading(false);
		}

	}


	return (
		<CenteredPopin
			isOpen={isOpen}
			onCacheClick={() => {
				// On ne permet pas de fermer la popin si c'est loading
				if (!loading) close();
			}}
		>
			<div className={styles.popin}>
				{loading ? (
					<Loader />
				) : error ? (
					<p className={styles.error}>{error}</p>
				) : commandeCree ? (
					<>
						<h1>
							Commande passée <span className={styles.doneCheck} />
						</h1>

						<p>
							Votre commande à bien été passée. Vous pouvez la retrouver dans
							vos commandes et suivre son statut.
						</p>

						<Link to="/commandes" onClick={close}>
							<Button>Mes commandes</Button>
						</Link>
					</>
				) : (
					<>
						<h1>Attention</h1>

						<p>
							Les prix affichés sont des estimations et n'engagent pas le
							fournisseur. En cliquant sur le bouton ci-dessous, vous vous
							engagez à payer le montant figurant sur la facture que vous
							recevrez.
						</p>


						<div className={styles.divid}></div>


						<p>
							Date de livraison estimé :
						</p>
						{fournisseurArray?.length > 0 ? fournisseurArray?.map((e: any) => {
							return (
								<div>
									<p><b>{e.enseigne}</b> : <b>{e.estimated}</b></p>
								</div>
							);
						}) : (<></>)}

						<Button onClick={placeOrder}>Je confirme ma commande</Button>
					</>
				)}
			</div>
		</CenteredPopin>
	);
}

function mapStateToProps(state: AppState) {
	const { isOpen, ...panier } = state.panier;
	return panier;
}

export default connect(mapStateToProps, { resetPanier })(
	_PopinCreationCommande,
);
