import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTitle } from '../../../hooks/useTitle';
import { AppState } from '../../../store';
import { PanierState } from '../../../store/panier/types';
import Button from '../../Common/Button/Button';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './CommandeEnCours.module.scss';
import Header from './Header/Header';
import PopinCreationCommande from './PopinCreationCommande/PopinCreationCommande';
import ProduitsIndisponibles from './ProduitsIndisponibles/ProduitsIndisponibles';
import TableProduits from './TableProduits/TableProduits';

export type Props = Pick<PanierState,
	'lignes' | 'dateLivraison' | 'errorInfoProduits' | 'loadingInfoProduits'>;

export function _CommandeEnCours({
																	 lignes,
																	 dateLivraison,
																	 errorInfoProduits,
																	 loadingInfoProduits,
																 }: Props) {
	useTitle('Commande en cours');
	let fournisseurFranco = '';
	const [popinCreationOpen, setPopinCreationOpen] = useState(false);
	const [commande, setCommande] = useState([]);
	let error = '';
	const todayDate = new Date(new Date().toDateString());

	const indisponibles = lignes.filter(
		l => !l.produit.tarif || l.produit.disabled,
	);

	const fournisseurs = lignes
		.filter(l => l.produit.tarif && !l.produit.disabled)
		.reduce((acc, val) => {
			const idFournisseur = val.produit.tarif!.fournisseur.id;
			const obj = acc.find(f => f.idFournisseur === idFournisseur);
			if (!obj) {
				acc.push({ idFournisseur, lignes: [val] });
			} else {
				obj.lignes.push(val);
			}
			return acc;
		}, [] as { idFournisseur: string; lignes: typeof lignes }[]);

	useEffect(() => {
		const franco = lignes
			.filter(l => l.produit.tarif && !l.produit.disabled)
			.reduce((acc, val) => {
				const idFournisseur = val.produit.tarif!.fournisseur.id;
				const obj = acc.find(f => f.idFournisseur === idFournisseur);
				if (!obj) {
					acc.push({ idFournisseur, franco: (val.produit.tarif?.fournisseur as any).franco });
				}
				return acc;
			}, [] as { idFournisseur: string; franco: any }[]);

		const date = '';
	});

	// Nécessaire pour unifier les timezones
	if (dateLivraison) dateLivraison = new Date(dateLivraison.toDateString());

	if (!dateLivraison) {
		error = `Veuillez choisir une date de livraison`;
	} else if (dateLivraison < todayDate) {
		error = `La date de livraison ne peut être antérieure à aujourd'hui`;
	} else if (!lignes.length) {
		error = `Veuillez mettre des produits dans votre panier`;
	} else if (indisponibles.length) {
		error = `Certains produits dans votre panier ne sont pas disponibles`;
	}

	function displayBtn() {
		return commande ? (
				<Button inverted onClick={() => setPopinCreationOpen(true)}>
					Valider la commande
				</Button>)
			:
			(<div>
				Le montant total n'est pas assez haut.
			</div>);
	}

	async function cmd(fournissuer: any) {
		fournisseurFranco += fournissuer + ', ';
		error = `Le montant total de la commande n'est pas suffisant pour les fournisseur : ${fournisseurFranco}-`;
		error = error.replace(', -', '');

	}

	return (
		<TableWrapper>
			{/* Header */}
			<Header />

			{/* Produits indisponibles */}
			<ProduitsIndisponibles
				lignes={indisponibles.map(l => ({
					id: l.produit.id,
					nom: l.produit.nom,
				}))}
			/>

			{/* Produits */}
			{!!lignes.length ? (
				fournisseurs.map((f, index) => {
					const {
						enseigne,
						dateLivraison,
						franco,
						timeLivraison,
						dayDelivery,
						datesFermetureExceptionnelle,
						optionLivraison,
					} = f.lignes[0].produit.tarif!.fournisseur;
					const hasAucours = lignes.filter((e) => e.produit.tarif?.fournisseur.id === f.idFournisseur).filter((e) => e.produit.aucour);
					const lig = lignes.filter((e) => e.produit.tarif?.fournisseur.id === f.idFournisseur).map((e) => {
						return (e as any).ancienPrix * e.quantite;
					});
					const sum = lig.reduce((a, b) => a + b);
					let valid = true;
					if (!hasAucours.length) {
						if (!(sum >= franco)) {
							valid = false;
							cmd(enseigne);
						}
					}
					return (
						<TableProduits
							valid={valid}
							key={f.idFournisseur}
							id={f.idFournisseur.toString()}
							error={errorInfoProduits}
							loading={loadingInfoProduits}
							fournisseur={{ enseigne,datesFermetureExceptionnelle, dateLivraison, franco, optionLivraison, timeLivraison, dayDelivery }}
							lignes={f.lignes.map(l => {
								return {
									quantite: l.quantite,
									ancienPrix: l.ancienPrix,
									produit: {
										id: l.produit.id,
										nom: l.produit.nom,
										unite: l.produit.unite,
										labels: l.produit.labels,
										imageSrc: l.produit.imageSrc,
										tarif: { prix: l.produit.aucour ? -1 : l.produit.tarif!.prix },
									},
								};
							})}
						/>
					);
				})
			) : (
				<TableProduits
					valid={true}
					lignes={[]}
					id={''}
					error={errorInfoProduits}
					loading={loadingInfoProduits}
				/>
			)}

			{/* Footer */}
			<footer className={styles.footer}>
				{!error ? displayBtn() : (
					<p className={styles.invalidOrder}>
						<span className={styles.exclamation}>!</span> {error}
					</p>
				)}
			</footer>

			{/* Popin création */}
			<PopinCreationCommande
				isOpen={popinCreationOpen}
				close={() => setPopinCreationOpen(false)}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return state.panier;
}

export default connect(mapStateToProps)(_CommandeEnCours);
