import React from 'react';
import { NavLink } from 'react-router-dom';
import SideMenuWrapper from '../../Common/SideMenuWrapper/SideMenuWrapper';
import styles from './SideMenu.module.scss';

const pages: { title: string; path: string }[] = [
	// { title: 'Accueil', path: '/' },
	{ title: 'Commandes', path: '/commandes' },
	{ title: 'Compte', path: '/compte' },
	{ title: 'Clients', path: '/clients' },
];

function SideMenu() {
	return (
		<SideMenuWrapper>
			{pages.map((p, i) => (
				<NavLink
					exact
					key={i}
					to={p.path}
					children={p.title}
					className={styles.link}
					activeClassName={styles.active}
				/>
			))}
		</SideMenuWrapper>
	);
}

export default SideMenu;
