import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useTitle } from '../../../hooks/useTitle';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import FormulaireFournisseur from '../FormulaireFournisseur/FormulaireFournisseur';
import { parseDelaiLivraison } from '../../../utils/parse';

const createMutation = loader('./createFournisseur.graphql');
const createMutationContact = loader('./createFournisseurContact.graphql');

const createContact = loader('./createFournisseurContact.graphql');

function CreationFournisseur() {
	useTitle('Nouveau Fournisseur');

	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);

	const [disabled, changeDisabled] = useState('');
	const [login, changeLogin] = useState('');
	const [email, changeEmail] = useState('');

	const [raisonSociale, changeRaisonSociale] = useState('');


	const [enseigne, changeEnseigne] = useState('');
	const [siret, changeSiret] = useState('');
	const [description, changeDescription] = useState('');
	const [search, changeSearch] = useState('');
	const [prelevementAuto, changePrelevementAuto] = useState('');
	const [kbis, changeKbis] = useState('');
	const [rib, changeRib] = useState('');
	const [photo, changePhoto] = useState('');
	const [nomContactCompta, changeNomContactCompta] = useState('');
	const [prenomContactCompta, changePrenomContactCompta] = useState('');
	const [emailContactCompta, changeEmailContactCompta] = useState('');
	const [telephoneContactCompta, changeTelephoneContactCompta] = useState('');
	const [secteurActivite, changeSecteurActivite] = useState('');
	const [numeroVoieLivraison, changeNumeroVoieLivraison] = useState('');
	const [codePostalLivraison, changeCodePostalLivraison] = useState('');
	const [villeLivraison, changeVilleLivraison] = useState('');
	const [numeroVoieFacturation, changeNumeroVoieFacturation] = useState('');
	const [codePostalFacturation, changeCodePostalFacturation] = useState('');
	const [villeFacturation, changeVilleFacturation] = useState('');
	const [horairesOuverture, changeHorairesOuverture] = useState('');
	const [datesFermetureExceptionnelle, changeDatesFermetureExceptionnelle] =
		useState('');
	const [franco, changeFranco] = useState('');

	const [joursLivraison, changeJoursLivraison]: any = useState([]);
	const [jourLivraison, changeJourLivraison]: any = useState('');

	const [numeroTVAIntracommunautaire, changeNumeroTVAIntracommunautaire] =
		useState('');
	const [conditionPaiement, changeConditionPaiement] = useState('');
	const [heureLimite, changeHeureLimite] = useState('');
	const [nomContactReferent, changeNomContactReferent] = useState('');
	const [prenomContactReferent, changePrenomContactReferent] = useState('');
	const [emailContactReferent, changeEmailContactReferent] = useState('');
	const [clients, changeClients] = useState([
		{ email: '', nom: '', first: '', type: '', tel: '' },
	]);

	const [telephoneContactReferent, changeTelephoneContactReferent] =
		useState('');
	const [test, changeTest] = useState('');
	const [actif, changeActif] = useState('');
	const [optionLivraison, changeoptionLivraison] = useState('');

	const [imgSrc, setPhoto] = useState<File | undefined>();
	const [imgKbisSrc, setKbis] = useState<File | undefined>();
	const [imgRibSrc, setRib] = useState<File | undefined>();
	const [idPayement, changeIdPayement]: any = useState('');
	const [contactType, changeContactType]: any = useState('');
	const [contactNom, changeContactNom]: any = useState('');
	const [contactPrenom, changeContactPrenom]: any = useState('');
	const [contactEmail, changeContactEmail]: any = useState('');
	const [contactTelephone, changeContactTelephone]: any = useState('');

	useEffect(() => {
		changeJourLivraison(String(joursLivraison).split(','));
	}, [joursLivraison]);

	async function submit() {
		let heureLimites = parseInt(heureLimite);
		let francos = parseInt(franco);
		let opt = parseDelaiLivraison(optionLivraison);
		console.log(opt);
		try {
			setError('');
			setLoading(true);
			// console.log(disabled);
			const dataResponse = await request(createMutation, {
				// disabled,
				email,
				raisonSociale,
				login,
				enseigne,
				siret,
				description,
				optionLivraison: opt,
				numeroVoieFacturation,
				codePostalFacturation,
				villeFacturation,
				// prelevementAuto,
				kbis: imgKbisSrc,
				rib: imgRibSrc,
				photo: imgSrc,
				horairesOuverture,
				heureLimites,
				contactType,
				contactNom,
				contactPrenom,
				contactEmail,
				contactTelephone,
				secteurActivite,
				francos,
				jourLivraison,
				conditionPaiement,
				datesFermetureExceptionnelle,
				numeroTVAIntracommunautaire,
			});
			if (
				clients.length > 0 &&
				clients[0].email != '' &&
				clients[0].nom != '' &&
				clients[0].first != ''
			) {
				clients.forEach(async (item) => {
					await request(createContact, {
						fournisseurId: (dataResponse as any).createFournisseur.id,
						type: item.type,
						tel: item.tel,
						nom: item.nom,
						prenom: item.first,
						email: item.email,
					});
				});
			}
			if (isMounted.current) setDone(true);
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	}

	return done ? (
		<Redirect to="/admin/fournisseurs" />
	) : (
		<FormulaireFournisseur
			type="Création"
			{...{
				error,
				test,
				actif,
				loading,
				disabled,
				login,
				email,
				raisonSociale,
				enseigne,
				siret,
				description,
				optionLivraison,
				search,
				prelevementAuto,
				kbis,
				photo,
				rib,
				nomContactReferent,
				prenomContactReferent,
				emailContactReferent,
				telephoneContactReferent,
				nomContactCompta,
				prenomContactCompta,
				emailContactCompta,
				telephoneContactCompta,
				secteurActivite,
				numeroVoieLivraison,

				changeoptionLivraison,
				codePostalLivraison,
				villeLivraison,
				numeroVoieFacturation,
				codePostalFacturation,
				villeFacturation,
				horairesOuverture,
				heureLimite,
				conditionPaiement,
				clients,
				changeClients,
				idPayement,
				franco,
				joursLivraison,
				datesFermetureExceptionnelle,
				numeroTVAIntracommunautaire,
				changeDisabled,
				changeLogin,
				changeEmail,
				changeRaisonSociale,
				changeEnseigne,
				changeSiret,
				changeDescription,
				changeSearch,
				changePhoto,
				setPhoto,
				setKbis,
				setRib,
				contactType,
				contactNom,
				contactPrenom,
				contactEmail,
				contactTelephone,
				changePrelevementAuto,
				changeKbis,
				changeRib,
				changeHorairesOuverture,
				changeNomContactCompta,
				changePrenomContactCompta,
				changeEmailContactCompta,
				changeTelephoneContactCompta,
				changeSecteurActivite,
				changeNumeroVoieLivraison,
				changeCodePostalLivraison,
				changeVilleLivraison,
				changeNumeroVoieFacturation,
				changeCodePostalFacturation,
				changeVilleFacturation,
				changeJoursLivraison,
				changeConditionPaiement,
				changeHeureLimite,
				changeNomContactReferent,
				changeEmailContactReferent,
				changePrenomContactReferent,
				changeTelephoneContactReferent,
				changeNumeroTVAIntracommunautaire,
				changeFranco,
				changeDatesFermetureExceptionnelle,
				changeTest,
				changeActif,
				changeIdPayement,
				changeContactType,
				changeContactNom,
				changeContactPrenom,
				changeContactEmail,
				changeContactTelephone,
				submit,
			}}
		/>
	);
}

export default CreationFournisseur;
