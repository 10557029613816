import React, { useEffect, useState } from 'react';
import Media from 'react-responsive';
import { media } from '../../../utils/breakpoints/breakpoints';
import Alertes from '../../Common/Alertes/Alertes';
import BtnAlertes from '../../Common/BtnAlertes/BtnAlertes';
import Logo from '../../Common/Logo/Logo';
import MenuGeneral from '../../Common/MenuGeneral/MenuGeneral';
import BtnPanier from '../BtnPanier/BtnPanier';
import OmniSearch from '../OmniSearch/OmniSearch';
import styles from './Header.module.scss';
import Loupe from './Loupe/Loupe';
import PopinOmniSearch from './PopinOmniSearch/PopinOmniSearch';
import RecapRechercheOrLogo from './RecapRechercheOrLogo/RecapRechercheOrLogo';
import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import { NavLink } from 'react-router-dom';
import { UserResponse } from '../../../store/compte/types';
import { saveStateToLocalStorage } from '../../../utils/localStorage';

const links = [
	{ title: 'Accueil', to: '/' },
	{ title: 'Mes commandes', to: '/commandes' },
	{ title: 'Mes commandes favorites', to: '/commandes-favorites' },
	{ title: 'Mes produits favoris', to: '/produits-favoris' },
	{ title: 'Mon compte', to: '/compte' },
	{ title: 'Contact', to: '/contact' },
	{ title: 'Mentions légales', to: '/mentions-legales' },
];

const oldAdminQuery = loader('./queries/oldAdmin.graphql');

const loginWithId = loader('./queries/loginWithId.graphql');

function Header() {
	const [oldAdmin, setOldAdmin] = useState({});
	const [omniSearchMobileOpen, setOmniSearchMobileOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');


	async function goToAdmin() {
		const { id } = oldAdmin as any;


		const response = await request<UserResponse>(loginWithId, { id, model: 'Admin' });

		saveStateToLocalStorage('panier', { favorite: false, lignes: [], comments: [] });
		localStorage.removeItem('cmdFav')

		window.location.href = '/admin';
	}

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		setError('');

		type TResponse = { oldAdmin: {} };
		request<TResponse>(oldAdminQuery)
			.then(res => {
				if (res.oldAdmin) {
					setOldAdmin(res.oldAdmin);
				}
			})
			.catch(err => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<header className={styles.wrapper}>
			<Media query={media['<laptop']}>
				<RecapRechercheOrLogo onClick={() => setOmniSearchMobileOpen(true)} />

				<Loupe
					className={styles.loupeMobile}
					onClick={() => setOmniSearchMobileOpen(true)}
				/>

				<PopinOmniSearch
					isOpen={omniSearchMobileOpen}
					close={() => setOmniSearchMobileOpen(false)}
				/>
			</Media>

			<Media query={media['>=laptop']}>
				<Logo />

				<OmniSearch className={styles.omniSearch} />
				{Object.keys(oldAdmin).length ? (<button className="btnClasse" onClick={() => goToAdmin()}>
					<p className={styles.nom}>
						Retourner vers l'administartion
					</p>
				</button>) : (<></>)}

				<BtnAlertes />
				<BtnPanier />
			</Media>

			<MenuGeneral links={links} />
			<Alertes />
		</header>
	);
}

export default Header;
