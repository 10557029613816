import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	ActualitesState,
	ActualitesThunkResult,
	GET_ACTUALITES_FAIL,
	GET_ACTUALITES_START,
	GET_ACTUALITES_SUCCESS,
} from './types';

const query = loader('./getActualites.graphql');

export function getActualites(): ActualitesThunkResult {
	return async function(dispatch) {
		dispatch({ type: GET_ACTUALITES_START });
		try {
			const { list } = await request<{ list: ActualitesState['list'] }>(query);
			dispatch({ type: GET_ACTUALITES_SUCCESS, list });
		} catch (err) {
			dispatch({ type: GET_ACTUALITES_FAIL, error: err.message });
		}
	};
}
