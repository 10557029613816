import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import { AppState } from '../../../store';
import { hideLoginError, logIn } from '../../../store/compte/actions';
import { UserResponse } from '../../../store/compte/types';
import { french } from '../../../utils/french';
import request from '../../../utils/request';
import BtnConnexion from '../../Common/BtnConnexion/BtnConnexion';
import BtnEdit from '../../Common/BtnEdit/BtnEdit';
import BtnPoubelle from '../../Common/BtnPoubelle/BtnPoubelle';
import Button from '../../Common/Button/Button';
import Table from '../../Common/Table/Table';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import { RowProps } from '../../Common/Table/types';
import styles from './Clients.module.scss';
import { CompteMinimalist } from '../../Common/CompteUser/types';
const queryActu = loader('./queries/getClients.graphql');
const loginMutation = loader('./queries/login.graphql');
const loginWithId = loader('./queries/loginWithId.graphql');
const mutationDeleteClient = loader('./queries/deleteClient.graphql');
// const mutationDeleteActu = loader('./queries/deleteActualite.graphql');
// const mutationChangeOrderActu = loader(
// 	'./queries/changeOrderActualite.graphql',
// );
//
// export interface Actualite {
// 	id: string;
// 	url: string;
// 	titre: string;
// 	ordre: number;
// 	contenu: string;
// 	createdAt: Date;
// 	imageSrc: string;
// }
type Fournisseur = {
	id: string;
	enseigne: string;
}

export interface Client {
	id: string;
	enseigne: string;
	login: string;
	email: string;
	description: string;
	photo: string;
	edit: string;
	disabled: string;
	fournisseur: Fournisseur;
}

type Props = {
	compte: CompteMinimalist;
}

function Clients({ compte }: Props) {
	useTitle('Clients');

	const [clients, setClients] = useState<Client[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		setError('');

		type TResponse = { clients: Client[] };
		request<TResponse>(queryActu)
			.then((res) => {
				const filteredClients = res.clients.filter(
					(client) => {
						return client.fournisseur?.id === compte.id || compte.id.includes('.ADMIN');
					},
				);
				isMounted && setClients(filteredClients);
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, []);

	function deleteClient(id: string) {
		setError('');

		request(mutationDeleteClient, { id })
			.then(() => setClients(clients.filter((a) => a.id !== id)))
			.catch((err) => setError(err.message));
	}

	async function connect(id: string) {
		setError('');
		try {
			const response = await request<UserResponse>(loginWithId, {
				id,
				model: 'Client',
			});
			window.location.href = '/';
		} catch (error) {
			setError(error.message);
		}

		// window.location.reload()
	}
	async function clientEdit(id: string) {
		setError('');
		// const response = await request<UserResponse>(loginWithId, { id, model: 'Client' });

		// window.location.href = '';
		window.location.href = `/client/edition/${id}`;
		// window.location.reload()
	}

	function invertActus(index1: number, index2: number) {
		// On récupère les actus
		// const actu1: Actualite | null = actualites[index1];
		// const actu2: Actualite | null = actualites[index2];

		// Annule si une des deux n'existe pas (donc qu'on est à une extrémité de la liste)
		// if (!actu1 || !actu2) return;

		// On récupère les ids et ordres de chaque actu (nécessaire pour casser la référence objet)
		// const { id: id1, ordre: ordre1 } = actu1;
		// const { id: id2, ordre: ordre2 } = actu2;

		setError('');

		// request(mutationChangeOrderActu, { id1, id2, ordre1, ordre2 })
		// 	.then(() => {
		// 		const actus = actualites.map(a => {
		// 			if (a.id === actu1.id) a.ordre = ordre2;
		// 			else if (a.id === actu2.id) a.ordre = ordre1;
		// 			return a;
		// 		});
		// 		setActualites(actus.sort((a, b) => a.ordre - b.ordre));
		// 	})
		// 	.catch(err => setError(err.message));
	}

	return (
		<TableWrapper>
			<div>
				<div className={styles.buttonWrapper}>
					<Link to={{ pathname: '/client/creation' }}>
						<Button submit loading={loading}>
							Ajouter un client
						</Button>
					</Link>
				</div>
			</div>
			<Table
				error={error}
				loading={loading}
				noResultMessage={'Aucun Clients'}
				columns={[
					{ content: 'enseigne', center: true },
					{ content: 'login', center: true },
					{ content: 'email', center: true },
					{ content: 'fournisseur', center: true },
					{ content: 'description', center: true },
					{ content: 'Edit', isButton: true, center: true },
					{ content: 'Actif', center: true },
					{ content: 'Se connecter', center: true, isButton: true },
					{ content: 'Supprimer', center: true, isButton: true },
				]}
				rows={clients.map(
					(
						{ id, enseigne, login, email, photo, description, edit, disabled, fournisseur },
						i,
					) => {
						const sr: String = description;
						const enseigneFournisseur = fournisseur?.enseigne || "";
						return {
							id,
							mobileTitle: (
								<div className={styles.mobileTitle}>{french(login)}</div>
							),
							cells: [
								{ content: enseigne, className: styles.actuTitle },
								{ content: french(login), className: styles.actuTitle },
								{ content: french(email), className: styles.actuTitle },
								{ content: enseigneFournisseur, className: styles.actuTitle },
								{
									content: description
										? french(sr.substring(0, 150) + '...')
										: '',
									className: styles.actuTitle,
								},
								{
									content: (
										<BtnEdit
											title="Edition"
											onClick={() => {
												clientEdit(id);
											}}
										/>
									),
								},
								{
									content: disabled ? 'Inactif' : 'Actif',
									className: styles.actuTitle,
								},
								{
									stopPropagation: true,
									content: (
										<BtnConnexion
											title="Connexion"
											onClick={() => connect(id)}
										/>
									),
								},
								{
									stopPropagation: true,
									content: (
										<BtnPoubelle
											title="Supprimer l'actualité"
											onClick={() => deleteClient(id)}
										/>
									),
								},
							],
						} as RowProps;
					},
				)}
			/>

			{/*<div className={styles.buttonWrapper}>*/}
			{/*	<Link to={{ pathname: '/actualites/creation' }}>*/}
			{/*		<Button>Ajouter une actualité</Button>*/}
			{/*	</Link>*/}
			{/*</div>*/}
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isLoggingIn: state.compte.isLoggingIn,
		isLoggedIn: state.compte.isLoggedIn,
		loginError: state.compte.error,
		compte: state.compte,
	};
}

// export default Clients;
export default withRouter(
	connect(mapStateToProps, { logIn, hideLoginError })(Clients),
);
