import { loader } from 'graphql.macro';
import { ProduitForPanier } from '../../../fragments/produitForPanier/type';
import { loadStateFromLocalStorage } from '../../../utils/localStorage';
import request from '../../../utils/request';
import { User } from '../../compte/types';
import {
	GET_INFO_PRODUITS_PANIER_FAIL,
	GET_INFO_PRODUITS_PANIER_START,
	GET_INFO_PRODUITS_PANIER_SUCCESS,
	LocalStoragePanier,
	PanierActionTypes,
	PanierLigne,
	PanierThunkResult,
} from '../types';

const getInfoProduitsPanierQuery = loader('../getInfoProduitsPanier.graphql');

type QueryResponse = { produits: Omit<ProduitForPanier, 'codeFournisseur'>[] };

// Thunk & Start
export function getInfoProduitsPanier(idClient: User['id']): PanierThunkResult {
	return async function(dispatch) {
		dispatch({ type: GET_INFO_PRODUITS_PANIER_START });

		// On load le panier du local storage
		const localPanier = loadStateFromLocalStorage('panier');

		// Si pas de panier dans le local storage, on passe direct à la fin avec un panier vide
		if (!localPanier) {
			dispatch({ type: GET_INFO_PRODUITS_PANIER_SUCCESS, lignes: [] });
			return;
		}

		// Si y a un panier dans local storage, on charge les infos des produits
		try {
			const response = await request<QueryResponse>(
				getInfoProduitsPanierQuery,
				{
					idClient,
					dateLivraison: localPanier.dateLivraison,
					ids: localPanier.lignes.map(l => l.idProduit),
				},
			);
			dispatch(getInfoProduitsPanierSuccess(response, localPanier));
		} catch (err) {
			dispatch({ type: GET_INFO_PRODUITS_PANIER_FAIL, error: err.message });
		}
	};
}

// Success
function getInfoProduitsPanierSuccess(
	response: QueryResponse,
	localPanier: LocalStoragePanier,
): PanierActionTypes {
	const lignes = response.produits.map(produit => {
		const ligneInPanier = localPanier.lignes.find(
			l => l.idProduit === produit.id,
		)!;
		const { tarifs, ...rest } = produit;
		return {
			quantite: ligneInPanier.quantite,
			ancienPrix: ligneInPanier.ancienPrix,
			produit: { ...rest, tarif: produit.tarifs[produit.tarifs.length-  1] },
		} as PanierLigne; 
	});

	return { type: GET_INFO_PRODUITS_PANIER_SUCCESS,  lignes  };
}
