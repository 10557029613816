import { loader } from 'graphql.macro';
import React from 'react';
import { Produit } from '../../../../types';
import { convertUnite, french, thinSpace } from '../../../../utils/french';
import { photosProduitsSizes } from '../../../../utils/imgSizes';
import request from '../../../../utils/request';
import BtnFavori from '../../../Common/BtnFavori/BtnFavori';
import Img from '../../../Common/Img/Img';
import Label from '../../../Common/Label/Label';
import Prix from '../../../Common/Prix/Prix';
import Table from '../../../Common/Table/Table';
import { RowProps } from '../../../Common/Table/types';
import { QueryResponse } from '../DetailCommande';
import styles from './ListeProduits.module.scss';
const setProduitFavoriMutation = loader('./setProduitFavori.graphql');

interface Props {
	error?: string;
	loading?: boolean;
	lignes: QueryResponse['commande']['lignes'];
	commande: any;
	setProduitFavori: (id: Produit['id'], newState: boolean) => void;
}

function TableProduits({
	error,
	loading,
	lignes,
	setProduitFavori,
	commande,
}: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage="Cette commande n'existe pas"
			columns={[
				{ content: 'Photo', center: true, mobileHidden: true },
				{ content: 'Nom', mobileHidden: true },
				 { content: 'Code'}, 
				{ content: 'Labels' },
				{ content: 'Prix' },
				{ content: 'Quantité' },
				{ content: 'Total' },
				{ content: 'Commentaire' },
				{ content: 'Favori', center: true, isButton: true },
			]}
			rows={lignes.map((l) => {
				const image = (
					<Img
						src={l.produit.imageSrc}
						alt={l.produit.imageSrc}
						className={styles.photo}
						fallback="jpg"
						otherSizes={photosProduitsSizes}
						mediaQueries={[
							{ breakpoint: '<=mobile', width: 'calc(100vw - 80px)' },
							{ breakpoint: '<laptop', width: 'calc(50vw - 100px)' },
							{ width: 25 },
						]}
					/>
				);

				const isFavori = l.produit.isFavoriteForMe;

				return {
					id: l.id,

					mobileTitle: (
						<div className={styles.mobileTitle}>
							{image}
							{l.produit.nom}
						</div>
					),

					cells: [
						// Photo produit
						{ content: image },

						// Nom
						{ content: l.produit.nom, className: styles.nom },
						{content: l.produit.codeFournisseur.map((item) => item.code)},
						// Labels
						{
							content: l.produit.labels.length
								? l.produit.labels.map((label, i) => (
										<Label key={i}>{label.abreviation}</Label>
								  ))
								: null,
						},

						// Prix unitaire
						{
							content: (
								<Prix
									afficherNA
									aucour= {l.produit.aucour}
									unite={l.produit.unite}
									prix={l.prixUnitaireFinal}
								/>
							),
						},

						// Quantité
						{
							content: (
								<>
									<strong className={styles.big}>
										{french(
											l.quantite.toFixed(l.produit.unite === 'KG' ? 2 : 0),
										)}
									</strong>
									{thinSpace}
									{convertUnite(
										l.uc ? (l.uc as any) : l.produit.unite,
										l.quantite,
									)}
								</>
							),
						},

						// Total
						{
							content: (
								<Prix
									afficherNA
									aucour= {l.produit.aucour}
									prix={(l.prixUnitaireFinal || 0) * l.quantite}
								/>
							),
						},

						{ content: l.commentaire, className: styles.nom },

						// Favori
						{
							content: (
								<BtnFavori
									checked={isFavori}
									onClick={() => {
										setProduitFavori(l.produit.id, !isFavori);
										request(setProduitFavoriMutation, {
											favori: !isFavori,
											idProduit: l.produit.id,
										});
									}}
									title={
										isFavori
											? 'Enlever ce produit de mes favoris'
											: 'Ajouter ce produit à mes favoris'
									}
								/>
							),
						},
					],
				} as RowProps;
			})}
		/>
	);
}

export default TableProduits;
