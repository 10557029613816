import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import BtnBack from '../../Common/BtnBack/BtnBack';
import Button from '../../Common/Button/Button';
import Checkbox from '../../Common/Checkbox/Checkbox';
import Dropzone from '../../Common/Dropzone/Dropzone';
import ErrorMessage from '../../Common/Form/ErrorMessage/ErrorMessage';
import Form from '../../Common/Form/Form';
import Input from '../../Common/Form/Input/Input';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './FormulaireFournisseur.module.scss';

const getPaiementQuery = loader('../EditionFournisseur/getPaiement.graphql');
const getSecteurActiviteQuery = loader(
	'../EditionFournisseur/getSecteurActivite.graphql',
);

export interface Props {
	type: 'Édition' | 'Création';

	error?: string;
	loading?: boolean;
	test: string;
	actif: string;

	disabled: string;
	login: string;
	email: string;
	raisonSociale: string;
	enseigne: string;
	siret: string;
	description: string;
	search: string;
	photo: string;
	kbis: string;
	rib: string;

	horairesOuverture: string;
	nomContactReferent: string;
	prenomContactReferent: string;
	emailContactReferent: string;
	telephoneContactReferent: string;
	nomContactCompta: string;
	prenomContactCompta: string;
	emailContactCompta: string;
	telephoneContactCompta: string;
	secteurActivite: string;
	numeroVoieLivraison: string;
	codePostalLivraison: string;
	villeLivraison: string;
	numeroVoieFacturation: string;
	codePostalFacturation: string;
	villeFacturation: string;
	heureLimite: string;
	franco: string;
	joursLivraison: string;
	datesFermetureExceptionnelle: string;
	numeroTVAIntracommunautaire: string;
	conditionPaiement: string;
	prelevementAuto: string;

	idPayement: string;
	contactType: string;
	contactNom: string;
	contactPrenom: string;
	contactEmail: string;
	contactTelephone: string;
	optionLivraison: string;
	changeClients: any;
	clients: any;
	changeTest: (val: string) => void;
	changeActif: (val: string) => void;

	changeDisabled: (val: string) => void;
	changeLogin: (val: string) => void;
	changeEmail: (val: string) => void;
	changeRaisonSociale: (val: string) => void;
	changeEnseigne: (val: string) => void;
	changeSiret: (val: string) => void;
	changeDescription: (val: string) => void;
	changeSearch: (val: string) => void;
	changePhoto: (val: string) => void;
	setPhoto: (val: File) => void;
	setKbis: (val: File) => void;
	setRib: (val: File) => void;
	changeoptionLivraison: (val: string) => void;
	changeKbis: (val: string) => void;
	changeRib: (val: string) => void;
	changeHorairesOuverture: (val: string) => void;
	changeNomContactReferent: (val: string) => void;
	changePrenomContactReferent: (val: string) => void;
	changeEmailContactReferent: (val: string) => void;
	changeTelephoneContactReferent: (val: string) => void;
	changeNomContactCompta: (val: string) => void;
	changePrenomContactCompta: (val: string) => void;
	changeEmailContactCompta: (val: string) => void;
	changeTelephoneContactCompta: (val: string) => void;
	changeSecteurActivite: (val: string) => void;
	changeNumeroVoieLivraison: (val: string) => void;
	changeCodePostalLivraison: (val: string) => void;
	changeVilleLivraison: (val: string) => void;
	changeNumeroVoieFacturation: (val: string) => void;
	changeCodePostalFacturation: (val: string) => void;
	changeVilleFacturation: (val: string) => void;
	changeHeureLimite: (val: string) => void;
	changeFranco: (val: string) => void;

	changeJoursLivraison: (val: string) => void;

	changeDatesFermetureExceptionnelle: (val: string) => void;
	changeNumeroTVAIntracommunautaire: (val: string) => void;
	changeConditionPaiement: (val: string) => void;
	changePrelevementAuto: (val: string) => void;

	changeIdPayement: (val: string) => void;
	changeContactType: (val: string) => void;
	changeContactNom: (val: string) => void;
	changeContactPrenom: (val: string) => void;
	changeContactEmail: (val: string) => void;
	changeContactTelephone: (val: string) => void;

	submit: () => void;
}

function FormulaireFournisseur({
																 type,
																 error,
																 loading,
																 disabled,
																 login,
																 test,
																 actif,
																 email,
																 raisonSociale,
																 enseigne,
																 siret,
																 description,
																 search,
																 photo,
																 kbis,
																 rib,
																 horairesOuverture,
																 nomContactReferent,
																 prenomContactReferent,
																 emailContactReferent,
																 telephoneContactReferent,
																 nomContactCompta,
																 prenomContactCompta,
																 emailContactCompta,
																 telephoneContactCompta,
																 secteurActivite,
																 numeroVoieLivraison,
																 codePostalLivraison,
																 villeLivraison,
																 numeroVoieFacturation,
																 codePostalFacturation,
																 villeFacturation,
																 heureLimite,
																 franco,
																 joursLivraison,
																 datesFermetureExceptionnelle,
																 numeroTVAIntracommunautaire,
																 conditionPaiement,
																 prelevementAuto,
																 idPayement,
																 contactType,
																 contactNom,
																 contactPrenom,
																 contactEmail,
																 contactTelephone,

																 changeTest,
																 changeActif,

																 changeDisabled,
																 changeLogin,
																 changeEmail,
																 changeRaisonSociale,
																 changeEnseigne,
																 changeSiret,
																 changeDescription,
																 changeSearch,
																 changePhoto,
																 setPhoto,
																 setKbis,
																 setRib,
																 changeKbis,
																 changeRib,
																 changeHorairesOuverture,
																 changeNomContactReferent,
																 changePrenomContactReferent,
																 changeEmailContactReferent,
																 changeTelephoneContactReferent,
																 changeNomContactCompta,
																 changePrenomContactCompta,
																 changeEmailContactCompta,
																 changeTelephoneContactCompta,
																 changeSecteurActivite,
																 changeNumeroVoieLivraison,
																 changeCodePostalLivraison,
																 changeVilleLivraison,
																 changeNumeroVoieFacturation,
																 changeCodePostalFacturation,
																 changeVilleFacturation,
																 changeHeureLimite,
																 changeFranco,
																 optionLivraison,
																 changeoptionLivraison,
																 changeJoursLivraison,

																 changeDatesFermetureExceptionnelle,
																 changeNumeroTVAIntracommunautaire,
																 changeConditionPaiement,
																 changePrelevementAuto,
																 changeIdPayement,
																 clients,
																 changeClients,
																 changeContactType,
																 changeContactNom,
																 changeContactPrenom,
																 changeContactEmail,
																 changeContactTelephone,

																 submit,
															 }: Props) {
	const [check, setCheck] = useState(false);
	const [check2, setCheck2] = useState(false);
	const [newId, setNewId]: any = useState();
	const [newSecteurActivite, setNewSecteurActivite]: any = useState();
	const [payeSelected, setPayeSelected]: any = useState([{ id: '' }]);
	const [secteurSelected, setSecteurSelected]: any = useState([{ id: '' }]);
	const url = window.location.pathname.split('/')[2];

	const { isMounted } = useUnmount();

	useEffect(() => {
		//@ts-ignore
		setPayeSelected(conditionPaiement);
	}, [conditionPaiement, payeSelected]);

	// if (url === 'creation') {
	// 	changeDisabled(check as any);
	// }

	useEffect(() => {
		let isMounted = true;
		type TResponse = { conditionsPaiement: [] };
		request<TResponse>(getPaiementQuery)
			.then((res) => {
				if (res) {
					setNewId(res.conditionsPaiement);
				}
			})
			.catch((err) => {
			});

		return () => {
			isMounted = false;
		};
	}, [isMounted]);

	useEffect(() => {
		let isMounted = true;
		type TResponse = { secteursActivite: [] };
		request<TResponse>(getSecteurActiviteQuery)
			.then((res) => {
				if (res) {
					setNewSecteurActivite(res.secteursActivite);
				}
			})
			.catch((err) => {
			});

		return () => {
			isMounted = false;
		};
	}, [isMounted]);

	const options = [
		{ value: 'chocolate', label: 'Chocolate' },
		{ value: 'strawberry', label: 'Strawberry' },
		{ value: 'vanilla', label: 'Vanilla' },
	];

	function pushClient() {
		let loadClient = [...clients];
		loadClient.push({ email: '', nom: '', first: '' });
		changeClients(loadClient as any);
	}

	function deleteClient(index: any) {
		let loadCLient = [...clients];
		changeClients(loadCLient.splice(0, index) as any);
	}

	function changeValContact(index: any, key: string, val: any) {
		if (key === 'nom') {
			clients[index].nom = val;
			// if (type === 'Édition') {
			// 	console.log(val);
			// } else {
			// 	clients[index].nom = clients[index].nom + val;
			// }
		}

		if (key === 'email') {
			clients[index].email = val;
			// if (type === 'Édition') {
			// 	console.log(val);
			// } else {
			// 	clients[index].email = clients[index].email + val;
			// }
		}

		if (key === 'first') {
			clients[index].first = val;
			// if (type === 'Édition') {
			// 	console.log(val);
			// } else {
			// 	clients[index].first = clients[index].first + val;
			// }
		}

		if (key === 'type') {
			clients[index].type = val;
		}
		if (key === 'tel') {
			clients[index].tel = val;
		}
	}

	return (
		<TableWrapper>
			<TableHeader>
				<BtnBack to="/admin/fournisseurs" className={styles.btnBack} withText />
				<p className={styles.title}>{type} d'un Fournisseur</p>
			</TableHeader>

			<Form onSubmit={submit} className={styles.newform}>
				<div className={styles.container}>
					<div className={styles.table1}>
						<div className={styles.prelevement}>
							{url === 'edition' ? (
								<label className={styles.label}>Inactif</label>
							) : (
								''
							)}

							{url === 'edition' ? (
								// <input
								// 	name="isGoing"
								// 	type="checkbox"
								// 	checked={check}
								// 	onChange={() => {
								// 		setCheck(!check);
								// 		changeDisabled(check as any);
								// 	}}
								// />
								<Checkbox
									className={styles.checkbox}
									loading={loading}
									checked={disabled as any}
									onClick={changeDisabled as any}
								/>
							) : (
								''
							)}
						</div>

						<div>
							<Input
								label="Login"
								type="text"
								value={login}
								setValue={changeLogin}
								className=""
								
							/>
						</div>
						<div>
							<Input
								label="Email"
								type="text"
								value={email}
								setValue={changeEmail}
								className=""
							/>
						</div>
						<div>
							<Input
								label="Raison Sociale"
								type="text"
								value={raisonSociale}
								setValue={changeRaisonSociale}
							/>
						</div>
						<div>
							<Input
								label="Enseigne"
								type="text"
								value={enseigne}
								setValue={changeEnseigne}
							/>
						</div>

						<div>
							<Input
								label="Description"
								type="text"
								value={description}
								setValue={changeDescription}
							/>
						</div>
						<div>
							<h1 className={styles.hh}>
								Secteur Activité <span className={styles.span}>*</span>
							</h1>
							<select
								className={styles.secteur}
								onChange={(l) => changeSecteurActivite(l.target.value)}
							>
								<option>--- Choisir une option</option>
								{newSecteurActivite === undefined ? (
									<option></option>
								) : (
									newSecteurActivite.map((e: any) => (
										<option value={e.id} selected={secteurActivite === e.id}>
											{e.nom}
										</option>
									))
								)}
							</select>
						</div>
						<div>
							{/* <div className={styles.prelevement}>
								<label className={styles.label}>
									Prelevement Auto <span className={styles.span}>*</span>
								</label>
								<input
									name="preleve"
									type="checkbox"
									checked={check2}
									onChange={() => {
										setCheck2(!check2);
										changePrelevementAuto(check2 as any);
									}}
								/>
							</div> */}
						</div>

						{/*<div className={styles.contact}>*/}
						{/*	<Input*/}
						{/*		label="Type de Contact"*/}
						{/*		value={contactType}*/}
						{/*		setValue={changeContactType}*/}
						{/*	/>*/}
						{/*	<Input*/}
						{/*		label="Nom"*/}
						{/*		value={contactNom}*/}
						{/*		setValue={changeContactNom}*/}
						{/*	/>*/}
						{/*	<Input*/}
						{/*		label="Prenom"*/}
						{/*		value={contactPrenom}*/}
						{/*		setValue={changeContactPrenom}*/}
						{/*	/>*/}
						{/*</div>*/}
						{/*<div className={styles.contact2}>*/}
						{/*	<Input*/}
						{/*		label="Email"*/}
						{/*		value={contactEmail}*/}
						{/*		setValue={changeContactEmail}*/}
						{/*	/>*/}
						{/*	<Input*/}
						{/*		label="Telephone"*/}
						{/*		value={contactTelephone}*/}
						{/*		setValue={changeContactTelephone}*/}
						{/*	/>*/}
						{/*</div>*/}

						<div className={styles.galerie}>
							<div className={styles.image}>
								<h1 className={styles.h1}>Photo</h1>
								<Dropzone
									imageSrc={photo}
									imageWidthToDisplay={600}
									onChange={({ tempURL, file }) => {
										setPhoto(file);
										changePhoto(tempURL);
									}}
								/>
							</div>

							<div className={styles.image}>
								<h1>
									Kbis <span className={styles.span}>*</span>
								</h1>
								<Dropzone
									imageSrc={kbis}
									imageWidthToDisplay={600}
									onChange={({ tempURL, file }) => {
										setKbis(file);
										changeKbis(tempURL);
									}}
								/>
							</div>
							<div className={styles.image}>
								<h1>
									Rib<span className={styles.span}>*</span>
								</h1>

								<Dropzone
									imageSrc={rib}
									imageWidthToDisplay={600}
									onChange={({ tempURL, file }) => {
										setRib(file);
										changeRib(tempURL);
									}}
								/>
							</div>
						</div>
					</div>

					<div className={styles.table2}>
						<div className={styles.facture}>
							<div>
								<Input
									label="N° et nom de voie facturation"
									value={numeroVoieFacturation}
									setValue={changeNumeroVoieFacturation}
								/>
								<Input
									label="Code postal facturation"
									value={codePostalFacturation}
									setValue={changeCodePostalFacturation}
								/>
								<Input
									label="Ville facturation"
									value={villeFacturation}
									setValue={changeVilleFacturation}
								/>
							</div>
						</div>

						<div>
							<Input
								label="Horaires Ouverture"
								type="text"
								value={horairesOuverture}
								setValue={changeHorairesOuverture}
							/>
						</div>
						<div>
							<Input
								label="Heure Limite (nombre seulement exemple: 12)"
								type="text"
								value={heureLimite}
								setValue={changeHeureLimite}
							/>
						</div>
						<div>
							<Input
								label="Franco (nombre)"
								type="text"
								value={franco as any}
								setValue={changeFranco}
							/>

							{/* <div className={styles.franco}>
								<select
									className={styles.select}
									name="franco"
									id="select-franco"
								>
									<option value="">--Choisir une option</option>
									<option value="apoura">A POUR A</option>
									<option value="apourb">A POUR B</option>
									<option value="apourc">A POUR C</option>
									<option value="apourd">A POUR D</option>
									<option value="apoure">A POUR E</option>
								</select>
								<input
									className={styles.heure}
									type="text"
									defaultValue="10:00"
								/>
							</div> */}
						</div>
						<div>
							<Input
								label="Jours De Livraison (LUNDI,MARDI,MERCREDI...)"
								type="text"
								value={joursLivraison}
								setValue={changeJoursLivraison}
							/>
						</div>
						<div>
							<Input
								label="Option de livraison (A -> A)"
								type="text"
								value={optionLivraison}
								setValue={changeoptionLivraison}
							/>
						</div>
						<div>
							<Input
								label="Dates De Fermeture Exceptionnelle * (yyyy-mm-dd)"
								type="text"
								value={datesFermetureExceptionnelle}
								setValue={changeDatesFermetureExceptionnelle}
							/>
						</div>
						<div>
							<Input
								label="N° de tva intracommunautaire"
								type="text"
								value={numeroTVAIntracommunautaire}
								setValue={changeNumeroTVAIntracommunautaire}
							/>
						</div>
						<div>
							<h1 className={styles.h}>
								Conditions de paiement <span className={styles.span}>*</span>
							</h1>

							<select
								className={styles.paie}
								onChange={(l) => changeConditionPaiement(l.target.value)}
							>
								<option>--- Choisir une option</option>
								{newId === undefined ? (
									<option></option>
								) : (
									newId.map((e: any) => (
										<option value={e.id} selected={payeSelected === e.id}>
											{e.nom}
										</option>
									))
								)}
							</select>
						</div>
					</div>
				</div>

				<div>
					<div className={styles.btnCtc} onClick={() => pushClient()}>
						Ajouter un contact
					</div>
					{clients.map((item: any, index: any) => {
						return (
							<div className={styles.ctnCtc} key={index}>
								<div
									onClick={() => deleteClient(index)}
									className={styles.deleteBtn}
								>
									Supprimer
								</div>
								<div>
									<Input
										label="Type"
										type="text"
										placeholder={item.type}
										value={item.typeVal}
										setValue={(valType) =>
											changeValContact(index, 'type', valType)
										}
									/>
								</div>
								<div>
									<Input
										label="Nom"
										type="text"
										placeholder={item.nom}
										value={item.nomVal}
										setValue={(val) => changeValContact(index, 'nom', val)}
									/>
								</div>
								<div>
									<Input
										label="Prénom"
										type="text"
										placeholder={item.first}
										value={item.firstVal}
										setValue={(valPrenom) =>
											changeValContact(index, 'first', valPrenom)
										}
									/>
								</div>
								<div>
									<Input
										label="Email"
										type="text"
										placeholder={item.email}
										value={item.emailVal}
										setValue={(valEmail) =>
											changeValContact(index, 'email', valEmail)
										}
									/>
								</div>
								<div>
									<Input
										label="Téléphones"
										type="text"
										placeholder={item.tel}
										value={item.telVal}
										setValue={(valTel) =>
											changeValContact(index, 'tel', valTel)
										}
									/>
								</div>
							</div>
						);
					})}
				</div>
				<ErrorMessage>{error}</ErrorMessage>
				<div>
					<Button submit className={styles.btnSubmit}>
						{type === 'Édition'
							? 'Éditer un fournisseur'
							: 'Publier un fournisseur'}
					</Button>
				</div>
			</Form>
		</TableWrapper>
	);
}

export default FormulaireFournisseur;
