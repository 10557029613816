import React from 'react';
import { connect } from 'react-redux';
import { removeProduitFromPanier } from '../../../../store/panier/actions';
import { PanierLigne } from '../../../../store/panier/types';
import { photosProduitsSizes } from '../../../../utils/imgSizes';
import BtnPoubelle from '../../../Common/BtnPoubelle/BtnPoubelle';
import Img from '../../../Common/Img/Img';
import Label from '../../../Common/Label/Label';
import Prix from '../../../Common/Prix/Prix';
import PlusMoinsPanier from '../../PlusMoinsPanier/PlusMoinsPanier';
import styles from './LignePanier.module.scss';

export interface Props extends PanierLigne {
	removeProduitFromPanier: typeof removeProduitFromPanier;
}

export function _LignePanier({
	quantite,
	ancienPrix,
	removeProduitFromPanier,
	produit: { id, disabled, imageSrc, labels, nom, unite, tarif, aucour, codeFournisseur},
}: Props) {
	return (
		<div className={styles.wrapper}>
			{/* Données du produit */}
			<div className={styles.produitData}>
				{/* Photo */}
				<div className={styles.svgPanier}>
					<Img
						src={imageSrc}
						alt={nom}
						fallback="jpg"
						className={styles.img}
						otherSizes={photosProduitsSizes}
						mediaQueries={[
							{ breakpoint: '<=mobile', width: 100 },
							{ breakpoint: '<=tablet', width: 260 },
							{ breakpoint: '<=laptop', width: 360 },
							{ breakpoint: '>laptop', width: 100 },
						]}
					/>
				</div>

				{/* Nom & labels */}
				<div className={styles.description}>
					<p className={styles.code}>{codeFournisseur.map((item) =>item.code )}</p>
					<h1 className={styles.nom}>{nom}</h1>
					
					{labels.map((label, i) => (
						<Label key={i}>{label.abreviation}</Label>
					))}
				</div>
			</div>

			{/* Enlever produit du panier */}
			<BtnPoubelle
				title="Enlever le produit du panier"
				className={styles.delete}
				onClick={() => removeProduitFromPanier(id)}
			/>

			{/* Messages d'erreur */}
			{disabled ? (
				<p className={styles.error}>
					Ce produit n'est plus disponible.
					<br /> Veuillez le supprimer de votre panier.
				</p>
			) : !tarif ? (
				<p className={styles.error}>
					Ce produit n'est plus disponible à ce tarif.
					<br /> Veuillez le supprimer ou choisir un autre fournisseur.
				</p>
			) : (
				ancienPrix !== tarif.prix && (
					<p className={styles.warning}>
						Le prix de ce produit a changé depuis la dernière fois.
					</p>
				)
			)}

			{/* Si le prix a changé depuis qu'il a été ajouté au panier */}
			{!disabled && (
				<>
					{/* Prix unitaire & quantité */}
					<div className={styles.wrapperPrixQuantite}>
						{/* Prix unitaire */}
						<Prix
							className={styles.prix}
							prix={tarif?.prix}
							unite={unite}
							aucour={aucour ? aucour : tarif?.prix === 'Au cours'}
						/>

						{/* Quantité */}
						<PlusMoinsPanier idProduit={id} className={styles.plusMoins} />
					</div>

					{/* Total */}
					<p className={styles.total}>
						Total{' '}
						<Prix
							afficherNA
							className={styles.prixTotal}
							prix={
								typeof tarif === 'number' ? (tarif as any).prix * quantite : 0
							}
							aucour={aucour ? aucour : tarif?.prix === 'Au cours'}
						/>
					</p>
				</>
			)}
		</div>
	);
}

export default connect(null, { removeProduitFromPanier })(_LignePanier);
