import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from '../../store';
import { getLoggedInUser } from '../../store/compte/actions';
import { UserType } from '../../store/compte/types';
import { checkIfOnline } from '../../store/isOnline/actions';
import Admin from '../Admin/Admin';
import Client from '../Client/Client';
import AuthenticationRoutes, {
	authRoutes,
} from '../Common/AuthenticationRoutes/AuthenticationRoutes';
import Error404 from '../Common/Error404/Error404';
import InitLoader from '../Common/InitLoader/InitLoader';
import NoInternet from '../Common/NoInternet/NoInternet';
import Fournisseur from '../Fournisseur/Fournisseur';
import ValidateCommande from '../Common/ValidateCommande/ValidateCommande';
import { ToastProvider } from 'react-toast-notifications';
import RedirectCommande from '../Common/RedirectCommande/RedirectCommande';

export interface Props extends RouteComponentProps {
	isOnline: boolean;
	isLoggedIn: boolean;
	isGettingLoggedInUser: boolean;
	userType: UserType;

	checkIfOnline: typeof checkIfOnline;
	getLoggedInUser: typeof getLoggedInUser;
}

export function _App({
											 location: { pathname },
											 isGettingLoggedInUser,
											 isOnline,
											 userType,
											 isLoggedIn,
											 checkIfOnline,
											 getLoggedInUser,
										 }: Props) {
	useEffect(() => {
		// On check si le user est loggedIn avant toute chose
		getLoggedInUser();
		// Online / Offline
		window.addEventListener('online', checkIfOnline);
		window.addEventListener('offline', checkIfOnline);
		return () => {
			window.removeEventListener('online', checkIfOnline);
			window.removeEventListener('offline', checkIfOnline);
		};
	}, [getLoggedInUser, checkIfOnline]);

	// On n'affiche rien tant que l'on ne sait pas si l'utilisateur est connecté ou non
	if (isGettingLoggedInUser) return <InitLoader />;

	// Redirect to dispatch if not logged in, except for authentication routes
	const isAuthRoute = authRoutes.map(r => r.path).includes(pathname);
	if (pathname.includes('/validate/commande')) {
		return <ValidateCommande commande={pathname} />;
	}
	if (pathname.includes('/see/commande')) {
		return <RedirectCommande commande={pathname} />;

	}
	if (!isLoggedIn && pathname === '/admin') {
		return <Redirect to="/connexion-admin" />;
	} else {
		if (!isLoggedIn && !isAuthRoute) return <Redirect to="/connexion" />;
	}

	return (
		<>
			{isAuthRoute ? (
				<AuthenticationRoutes />
			) : userType === 'Client' ? (
				<Client />
			) : userType === 'Fournisseur' ? (
				<Fournisseur />
			) : userType === 'Admin' ? (
				<ToastProvider>
					<Admin />
				</ToastProvider>
			) : (
				<Error404 />
			)}

			{!isOnline && <NoInternet />}
		</>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isOnline: state.isOnline,
		userType: state.compte.type,
		isLoggedIn: state.compte.isLoggedIn,
		isGettingLoggedInUser: state.compte.isGettingLoggedInUser,
	};
}

export default withRouter(
	connect(mapStateToProps, { getLoggedInUser, checkIfOnline })(_App),
);
