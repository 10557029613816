import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { useTitle } from '../../../hooks/useTitle';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import FormulaireAdmin from '../FormulaireAdmin/FormulaireAdmin';

const createMutation = loader('./createAdmin.graphql');

function CreationAdmin() {
	useTitle('Nouveaux admin');

	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [disabled, changeDisabled] = useState('');

	const [login, changeLogin] = useState('');
	const [email, changeEmail] = useState('');
	const [nom, changeNom] = useState('');
	const [prenom, changePrenom] = useState('');
	const [password, changePassword] = useState('');

	async function submit() {
		try {
			setError('');
			setLoading(true);
			await request(createMutation, { login, email, nom, prenom, password });
			if (isMounted.current) setDone(true);
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	}

	return done ? (
		<Redirect to="/admin" />
	) : (
		<FormulaireAdmin
			type="Création"
			{...{
				error,
				loading,
				login,
				email,
				password,
				nom,
				prenom,
				changePrenom,
				changeNom,
				changeLogin,
				changeEmail,
				changePassword,
				submit,
			}}
		/>
	);
}

export default CreationAdmin;
