import React, { DragEvent, useState } from 'react';
import styles from './Dropzone.module.scss';
import { ReactComponent as UploadSVG } from './upload.svg';

export interface Props {
	imageSrc?: string;
	required?: boolean;
	className?: string;
	imageWidthToDisplay: number;
	onChange: (val: { file: File; tempURL: string }) => void;
}

function Dropzone({
	imageSrc,
	required,
	className,
	onChange,
	imageWidthToDisplay,
}: Props) {
	const [highlight, setHighlight] = useState(false);

	function handleFiles(files: FileList | null) {
		if (!files || !files.length) return;
		const file = files[0];
		if (
			!['image/png', 'image/jpeg'].includes(file.type) ||
			file.size > 10000000 // 10Mo
		)
			return;
		onChange({ file, tempURL: URL.createObjectURL(file) });
	}

	function onDragOver(e: DragEvent<HTMLLabelElement>) {
		e.preventDefault();
		setHighlight(true);
	}

	function onDrop(e: DragEvent<HTMLLabelElement>) {
		e.preventDefault();
		handleFiles(e.dataTransfer.files);
		setHighlight(false);
	}

	const cta = (
		<div className={styles.cta}>
			<UploadSVG className={styles.icon} />
			<p>Téléverser une image (jpeg ou png, 10Mo max)</p>
		</div>
	);

	return (
		<label
			onDrop={onDrop}
			onDragOver={onDragOver}
			onDragLeave={() => setHighlight(false)}
			className={[styles.wrapper, className, highlight && styles.highlight]
				.filter(Boolean)
				.join(' ')}
		>
			{imageSrc ? (
				<div className={styles.img}>
					<img
						alt=""
						src={imageSrc.replace(
							/@[0-9]+.webp$/,
							`@${imageWidthToDisplay}.webp`,
						)}
					/>
					{cta}
				</div>
			) : (
				<div className={styles.dropzone}>{cta}</div>
			)}

			<input
				type="file"
				className={styles.input}
				accept="image/jpeg, image/png"
				onChange={({ target: { files } }) => handleFiles(files)}
				required={required && !imageSrc}
			/>
		</label>
	);
}

export default Dropzone;
