import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router';
import { useTitle } from '../../../hooks/useTitle';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import FormulaireClient from '../FormulaireClient/FormulaireClient';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import { CompteMinimalist } from '../../Common/CompteUser/types';
// const getActualiteQuery = loader('./getActualite.graphql');
const getClientsQuery = loader('./getClient.graphql');
const updateClientMutation = loader('./updateClient.graphql');
const createContact = loader('./createClientContact.graphql');
const fournisseurs = loader('./getFournisseur.graphql');
const associateFournisseurs = loader('./associateClientFournisseur.graphql');
const updateClientEmail = loader('./updateMailClient.graphql');

interface Props {
	match: {
		params: {
			id: string;
		}
	};
	compte: CompteMinimalist;
}

function EditionClient({ match, compte }: Props) {
	const { id } = match.params;
	let { id: idFournisseur } = compte;
	useTitle(`Édition d'un client`);

	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);
	const [done, setDone] = useState(false);
	const [data, setData] = useState([]);

	const [disabled, changeDisabled] = useState('');
	const [login, changeLogin] = useState('');
	const [email, changeEmail] = useState('');

	const [raisonSociale, changeRaisonSociale] = useState('');
	const [enseigne, changeEnseigne] = useState('');
	const [siret, changeSiret] = useState('');
	const [description, changeDescription] = useState('');
	const [search, changeSearch] = useState('');
	const [prelevementAuto, changePrelevementAuto] = useState('');
	const [kbis, changeKbis] = useState('');
	const [rib, changeRib] = useState('');
	const [nomContactReferent, changeNomContactReferent] = useState('');
	const [prenomContactReferent, changePrenomContactReferent] = useState('');
	const [emailContactReferent, changeEmailContactReferent] = useState('');
	const [telephone1ContactReferent, changeTelephone1ContactReferent] =
		useState('');
	const [telephone2ContactReferent, changeTelephone2ContactReferent] =
		useState('');
	const [nomContactCuisine, changeNomContactCuisine] = useState('');
	const [prenomContactCuisine, changePrenomContactCuisine] = useState('');
	const [emailContactCuisine, changeEmailContactCuisine] = useState('');
	const [telephoneContactCuisine, changeTelephoneContactCuisine] = useState('');
	const [nomContactCompta, changeNomContactCompta] = useState('');
	const [prenomContactCompta, changePrenomContactCompta] = useState('');
	const [emailContactCompta, changeEmailContactCompta] = useState('');
	const [telephoneContactCompta, changeTelephoneContactCompta] = useState('');
	const [secteurActivite, changeSecteurActivite] = useState('');
	const [numeroVoieLivraison, changeNumeroVoieLivraison] = useState('');
	const [codePostalLivraison, changeCodePostalLivraison] = useState('');
	const [villeLivraison, changeVilleLivraison] = useState('');
	const [numeroVoieFacturation, changeNumeroVoieFacturation] = useState('');
	const [codePostalFacturation, changeCodePostalFacturation] = useState('');
	const [villeFacturation, changeVilleFacturation] = useState('');
	const [horairesOuverture, changeHorairesOuverture] = useState('');
	const [dateFermetureExceptionnelle, changeDateFermetureExceptionnelle] =
		useState('');
	const [fournisseursAutorise, changeFournisseursAutorise] = useState([]);
	const [fournisseursAutoriseVal, changeFournisseursAutoriseVal] = useState('');
	const [imgKbisSrc, setKbis] = useState<File | undefined>();
	const [imgRibSrc, setRib] = useState<File | undefined>();

	// const [photo, changePhoto] = useState<File | undefined>();
	const [photo, changePhoto] = useState('');
	const [imgSrc, setPhoto] = useState<File | undefined>();

	const [iban, changeIban] = useState('');
	const [bic, changeBic] = useState('');
	const [banque, changeBanque] = useState('');
	const [contacts, changeContacts] = useState('');
	const [backLink, setBackLink] = useState<string>('');

	const [clients, changeClients] = useState([
		{ id: '', email: '', nom: '', first: '', type: '', tel: '' },
	]);

	useEffect(() => {
		setError('');
		setLoading(true);

		let isMounted = true;
		type TResponse = { clients: [] };
		request<TResponse>(getClientsQuery, { id })
			.then((res: any) => {
				if (res) {
					setData(res.clients[0]);

					// if (res.clients[0].fournisseursAutorises.length > 0) {
					// 	changeFournisseursAutoriseVal(
					// 		res.clients[0].fournisseursAutorises.map((e: any) => e.id),
					// 	);
					// }
					if (res.clients[0].contacts && res.clients[0].contacts.length) {
						changeClients(
							res.clients[0].contacts.map((item: any) => {
								return {
									id: item.id,
									nom: item.nom,
									tel:
										item.telephones.length > 0
											? item.telephones.join(', ')
											: '',
									type: item.type,
									first: item.prenom,
									email: item.email,
								};
							}),
						);
					}
				}
			})
			.catch((err) => {
				setError(err.message);
			})
			.finally(() => setLoading(false));

		type TResponse2 = { fournisseurs: [] };
		request<TResponse2>(fournisseurs)
			.then((res: any) => {
				if (res) {
					changeFournisseursAutorise(
						res.fournisseurs.filter((e: any) => !e.disabled),
					);
				}
			})
			.catch((err) => {
				setError(err.message);
			});

		const link = idFournisseur.includes('.ADMIN')
			? '/admin/clients'
			: '/clients';
		setBackLink(link);

		return () => {
			isMounted = false;
		};
	}, [id, isMounted]);

	useEffect(() => {
		// @ts-ignore
		// console.log('data => ', data);
		if (data) {
			// @ts-ignore
			changeDisabled(data.disabled);
			// @ts-ignore
			changeLogin(data.login);
			// @ts-ignore
			changePhoto(data.photo);
			// @ts-ignore
			changeEmail(data.email);
			// @ts-ignore
			changeDisabled(data.disabled);
			// @ts-ignore
			changeRaisonSociale(data.raisonSociale);
			// @ts-ignore
			changeEnseigne(data.enseigne);
			// @ts-ignore
			changeSiret(data.siret);
			// @ts-ignore
			changeDescription(data.description);
			// @ts-ignore
			changeSearch(data.search);
			// @ts-ignore
			changePrelevementAuto(data.prelevementAuto);
			// @ts-ignore
			changeKbis(data.kbis);
			// @ts-ignore
			changeRib(data.rib);
			// @ts-ignore
			changeHorairesOuverture(data.horairesOuverture);
			// @ts-ignore
			changeDateFermetureExceptionnelle(data.datesFermetureExceptionnelle);
			// @ts-ignore
			changeBanque(data?.banque?.banque);
			// @ts-ignore
			changeBic(data?.banque?.bic);
			// @ts-ignore
			changeIban(data?.banque?.iban);
			// @ts-ignore
			// changeContacts(data[0]?.contacts);
			// @ts-ignore
			changeSecteurActivite(data?.secteurActivite?.id);
			// @ts-ignore
			changeNumeroVoieFacturation(data?.adresseFacturation?.voie);
			// @ts-ignore
			changeVilleFacturation(data?.adresseFacturation?.ville);
			// @ts-ignore
			changeCodePostalFacturation(data?.adresseFacturation?.codePostal);
		}
	}, [data]);

	async function submit() {
		try {
			setError('');
			setLoading(true);

			if (idFournisseur.includes('.ADMIN')) {
				idFournisseur = fournisseursAutoriseVal;
			}

			await request(updateClientMutation, {
				id,
				disabled,
				raisonSociale,
				enseigne,
				siret,
				description,
				secteurActivite,
				search,
				prelevementAuto,
				photo: imgSrc,
				kbis: imgKbisSrc,
				rib: imgRibSrc,
				horairesOuverture,
				dateFermetureExceptionnelle,
				banque,
				bic,
				iban,
				numeroVoieFacturation,
				villeFacturation,
				codePostalFacturation,
				idFournisseur,
			});

			await request(updateClientEmail, { id, email });
			if (
				clients.length > 0 &&
				clients[0].email !== '' &&
				clients[0].nom !== '' &&
				clients[0].first !== ''
			) {
				clients.forEach(async (item) => {
					await request(createContact, {
						clientId: id,
						id: item.id,
						type: item.type,
						tel: item.tel,
						nom: item.nom,
						prenom: item.first,
						email: item.email,
					});
				});
			}

			// if (fournisseursAutoriseVal.length > 0) {
			// 	fournisseursAutoriseVal.forEach(async (item) => {
			// 		console.log(item);
			// 		await request(associateFournisseurs, { id: id, fournisseurId: item });
			// 	});
			// }

			if (isMounted.current) {
				setDone(true);
			} else {
				if (isMounted.current) setDone(true);
			}
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	}

	return done ? (
		<Redirect to={backLink} />
	) : (
		<FormulaireClient
			type="Édition"
			backLink={backLink}
			{...{
				error,
				loading,
				disabled,
				login,
				email,
				raisonSociale,
				enseigne,
				siret,
				nomContactReferent,
				prenomContactReferent,
				emailContactReferent,
				telephone1ContactReferent,
				telephone2ContactReferent,
				nomContactCuisine,
				prenomContactCuisine,
				emailContactCuisine,
				telephoneContactCuisine,
				nomContactCompta,
				prenomContactCompta,
				emailContactCompta,
				telephoneContactCompta,
				secteurActivite,
				numeroVoieLivraison,
				codePostalLivraison,
				villeLivraison,
				numeroVoieFacturation,
				codePostalFacturation,
				villeFacturation,
				description,
				search,
				prelevementAuto,
				kbis,
				rib,
				setRib,
				clients,
				changeClients,
				iban,
				bic,
				banque,
				contacts,
				photo,
				fournisseursAutoriseVal,
				changeFournisseursAutoriseVal,
				horairesOuverture,
				dateFermetureExceptionnelle,
				fournisseursAutorise,
				changeDisabled,
				changeLogin,
				changeEmail,
				changeRaisonSociale,
				changeEnseigne,
				changeNomContactReferent,
				changeEmailContactReferent,
				changePrenomContactReferent,
				changeTelephone1ContactReferent,
				changeTelephone2ContactReferent,
				changeNomContactCuisine,
				changePrenomContactCuisine,
				changeEmailContactCuisine,
				changeTelephoneContactCuisine,
				changeNomContactCompta,
				changePrenomContactCompta,
				changeEmailContactCompta,
				changeTelephoneContactCompta,
				changeSecteurActivite,
				changeNumeroVoieLivraison,
				changeCodePostalLivraison,
				changeVilleLivraison,
				changeNumeroVoieFacturation,
				changeCodePostalFacturation,
				changeVilleFacturation,
				changeSiret,
				changeDescription,
				changeSearch,
				changePrelevementAuto,
				changeKbis,
				changeIban,
				changeBic,
				changeBanque,
				setKbis,
				changePhoto,
				setPhoto,
				changeRib,
				changeHorairesOuverture,
				changeDateFermetureExceptionnelle,
				changeFournisseursAutorise,
				changeContacts,
				submit,
			}}
		/>
	);
}

const mapStateToProps = (state: AppState) => ({
	compte: state.compte,
});

export default withRouter(connect(mapStateToProps)(EditionClient));
