import React, { Fragment } from 'react';
import MenuLink from '../MenuLink/MenuLink';
import SkeletonLine from '../SkeletonLine/SkeletonLine';
import TitleAndError from '../TitleAndError/TitleAndError';
import { HOCProps, withCategories } from '../withCategories';

export function _Categories({
	search,
	pathname,
	categories,
	errorCategories,
	loadingCategories,
	activeCategorieSlug,
	activeSousCategorieSlug,
}: HOCProps) {
	return (
		<>
			<TitleAndError error={errorCategories}>Les Catégories</TitleAndError>

			{loadingCategories
				? [...new Array(6)].map((_, i) => <SkeletonLine key={i} />)
				: categories.map(({ nom, pictoSrc, slug, sousCategories }) => {
						const params = new URLSearchParams(search);
						params.set('categorie', slug);

						const activeSousCategorie = sousCategories.find(
							sc => sc.slug === activeSousCategorieSlug,
						);

						return (
							<Fragment key={slug}>
								<MenuLink
									isCategorie
									pictoSrc={pictoSrc}
									to={`${pathname}?${params}`}
									isActive={slug === activeCategorieSlug}
									isSubActive={activeSousCategorieSlug.includes(slug + '_')}
								>
									{nom}
								</MenuLink>

								{activeSousCategorie && (
									<MenuLink isActive withOffset to={`${pathname}?${params}`}>
										{activeSousCategorie.nom}
									</MenuLink>
								)}
							</Fragment>
						);
				  })}
		</>
	);
}

export default withCategories(_Categories);
